<script setup lang="ts">
import OzBox, { type OzBoxColors } from '@@/library/v4/components/OzBox.vue'
import { useFloating } from '@@/vuecomposables/floating'
import type { ComputePositionConfig, ReferenceElement } from '@floating-ui/vue'
import { computed, ref, toRef, watch } from 'vue'

/* ---------------------- */
/* INTRODUCTION           */
/* ---------------------- */
/**
 * OzBasePopover is the base component for all our popovers.
 * It's not meant to be used directly, since it doesn't have a semantic representation.
 * Instead, it just acts as the core piece that encapsulates logic that
 * doesn't need to be visible or handled on the implementation phase.
 */

const props = withDefaults(
  defineProps<{
    /* ---------------------- */
    /* FUNCTIONALITY          */
    /* ---------------------- */
    /**
     * An anchor. Either an element or a pair of coordinates (x, y).
     */
    anchor: Anchor
    /**
     * Popover configuration. Uses Floating UI.
     * Reference: https://floating-ui.com/
     */
    config: Partial<ComputePositionConfig>

    /* ---------------------- */
    /* GENERAL APPEARANCE     */
    /* ---------------------- */
    /**
     * Dark mode
     */
    darkMode?: boolean | 'auto'

    /* ---------------------- */
    /* BOX APPEARANCE         */
    /* ---------------------- */
    /**
     * The popover body radius. Uses OzBox.
     */
    radius?: 0 | 8 | 12 | 16 | 20 | 24
    /**
     * The popover body color. Uses OzBox.
     */
    color?: OzBoxColors
  }>(),
  {
    darkMode: 'auto',
    radius: undefined,
    color: undefined,
  },
)

/**
 * The `floating` composable requires us to
 * pass the reference of the anchor element.
 * Since we want to keep the API simple, we'll handle
 * the reference internally by watching the passed prop.
 */
const anchorRef = ref<ReferenceElement | null>(null)

const isCoordinates = (anchor: Anchor): boolean => {
  return Object.prototype.hasOwnProperty.call(anchor, 'x') && Object.prototype.hasOwnProperty.call(anchor, 'y')
}

const getDomRectFromCoordinates = (element: Coordinates): DOMRect => {
  return new DOMRect(element.x, element.y, 0, 0)
}

watch(
  () => props.anchor,
  (el: Anchor): void => {
    if (isCoordinates(el)) {
      anchorRef.value = { getBoundingClientRect: () => getDomRectFromCoordinates(el as Coordinates) }
      return
    }
    anchorRef.value = el as ReferenceElement
  },
  { immediate: true },
)

const popover = ref<typeof OzBox | null>(null)
const popoverEl = computed(() => popover.value?.$el)

const configRef = toRef(props, 'config')
const { floatingStyles } = useFloating(anchorRef, popoverEl, configRef)
</script>

<script lang="ts">
interface Coordinates {
  x: number
  y: number
}

export type Anchor = Element | Coordinates

export default {}
export { OzBoxColors as OzBasePopoverColors } from '@@/library/v4/components/OzBox.vue'
</script>

<template>
  <OzBox ref="popover" :style="floatingStyles" :dark-mode="darkMode" :color="color" :radius="radius">
    <slot></slot>
  </OzBox>
</template>
